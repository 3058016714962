import { useState } from 'react'

import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'
import { privacyPolicyLink } from '../../pages/Privacy/Privacy'

const styles: Styles = {
  container: css({
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    padding: mTheme.size.XS,
    paddingBottom: mTheme.size.XS,
    fontSize: mTheme.fontSize.S,
    // width: '100%',
    background: mTheme.backgroundColor.primary,
    border: `2px solid ${mTheme.borderColor.secondary}`,
    borderRadius: mTheme.radius.primary,
  }),
  checkbox: css({
    marginRight: mTheme.size.XXS,
  }),
  label: css({
    width: '100%',
    userSelect: 'none',
  }),
}
type Props = {
  checked: boolean
  onChange: (value: boolean) => void
}

export function useLegalTermsCheckbox() {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)

  return {
    termsAccepted,
    setTermsAccepted,
    legalTermsCheckbox: (
      <LegalTermsCheckbox onChange={setTermsAccepted} checked={termsAccepted} />
    ),
  }
}

export const termsLink =
  'https://yupty.notion.site/Yupty-Terms-and-Conditions-0a24b084ccb0437f800b2a8c54fca0af?pvs=4'

const LegalTermsCheckbox = ({ onChange, checked }: Props) => {
  return (
    <div css={styles.container}>
      <input
        css={styles.checkbox}
        type="checkbox"
        id="terms-and-conditions"
        name="terms-and-conditions"
        checked={checked}
        onChange={() => {
          onChange(!checked)
        }}
      />
      <label htmlFor="terms-and-conditions" css={styles.label}>
        I accept the{' '}
        <a rel="noopener noreferrer" target="_blank" href={termsLink}>
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a rel="noopener noreferrer" target="_blank" href={privacyPolicyLink}>
          Privacy Policy.
        </a>
      </label>
    </div>
  )
}

export default LegalTermsCheckbox
